import React, { useState } from "react";
import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure, Input, Spinner} from "@nextui-org/react";
import { POST } from "../../../../utils/requests";
import { lesson_uri } from "../../../../utils/url";
import { toast } from "react-toastify";

export default function LessonAdd({callback}) {
  const {isOpen, onOpen, onOpenChange} = useDisclosure();
  const [data, setData] = useState({name:"", link:"", type:"", category:""});
  const [load, setLoad] = useState(false);

  const handleCall = () => {
      callback();
  }

  const Sub = async()=> {
    setLoad(true);
    try{
        const res = await POST({uri: lesson_uri, data});
        if(res.status === 200){
            toast.success("Амжилттай");
            handleCall();
            onOpenChange(false);
            setData({...data, name:"", link:"",type:""});
        }
        setLoad(false);
    }catch(err){
        setLoad(false);
        console.log(err);
    }
  }

  return (
    <>
      <Button onClick={onOpen}>Сургалт нэмэх</Button>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">Сургалт нэмэх</ModalHeader>
              <ModalBody>
                <div className="font-Roboto">
                    <h1 className="text-sm">Сургалтын нэр оруулах</h1>
                    <Input onChange={(e)=> setData({...data, name: e.target.value})} value={data.name} className="mt-1" placeholder="Нэр оруулах"/>

                    <h1 className="text-sm mt-3">Линк оруулах</h1>
                    <Input onChange={(e)=> setData({...data, link: e.target.value})} value={data.link} className="mt-1" placeholder="Линк оруулах"/>

                    <h1 className="text-sm mt-3">Төрөл оруулах</h1>
                    <select className="bg-gray-100 p-3 outline-none w-full rounded-md mt-1" onChange={(e)=> setData({...data, type:e.target.value})}>
                        <option>Сонгох</option>
                        <option value="YOUTUBE">Ютүб бичлэг</option>
                        <option value="FACEBOOK">Facebook заавар</option>
                    </select>

                    <h1 className="text-sm mt-3">Категори оруулах</h1>
                    <select className="bg-gray-100 p-3 outline-none w-full rounded-md mt-1" onChange={(e)=> setData({...data, category:e.target.value})}>
                        <option>Сонгох</option>
                        <option value="WEBSITE">Вебсайт хичээл</option>
                        <option value="TABAO">Taobao аппликейшн</option>
                    </select>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onClick={onClose}>
                  Close
                </Button>
                {
                    load ?
                    <Button color="primary">
                        <Spinner color="white"/>
                    </Button>
                    :
                    <Button color="primary" onClick={Sub}>
                        Оруулах
                    </Button>
                }
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
