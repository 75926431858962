import React, { useEffect, useState } from 'react'
import { Button, Spinner } from '@nextui-org/react'
import { GET } from '../../../utils/requests';
import Paginations from '../../../components/Pagination';
import { lesson_uri, users_uri } from '../../../utils/url';
import LessonAdd from './Modals/lessonadd';
import LessonDelete from './Modals/lessondelete';

const Lesson = () => {
  const [load, setLoad] = useState(false);
  const [data, setData] = useState([])
  const [pagination, setPagination] = useState({limit:15, all:'', totalPage:'',name:''});
  const [pay, setPay] = useState({onepay:'', allpay:''});
  const [page, setPage] = useState(1);

  useEffect(() => {
    Get();
  }, [page]);

  const Get = async () => {
    setLoad(true);
    const b = await GET(lesson_uri+`?page=${page}&limit=${pagination.limit}&name=${pagination.name}`);
    setData(b.data.data);
    setPagination({...pagination, all:b.data.all, totalPage:b.data.totalPage});
    setLoad(false);
  };

  const callback =()=> {
    Get();
  }
  
  const changePage = (page) => {
    setPage(page);
  };

  return (
    <div>
      <div>

      <div className="flex flex-col mr-2 mt-2 font-Roboto">
          <div className="overflow-x-auto shadow-md sm:rounded-md">
            <div className="inline-block min-w-full align-middle">
              <div className="overflow-hidden">
              <div className='bg-white p-4'>
                <div className='flex items-center justify-between mb-2'>
                  <h1 className='text-lg font-bold'>Сургалтын мэдээлэл</h1>
                  <div>
                    <LessonAdd callback={callback}/>
                  </div>
                </div>
                <div className='flex items-center gap-2'>
                  <input size='' className='border rounded-lg mt-2 focus:border-1 focus:border-blue-600 outline-none w-full p-2 bg-gray-100' placeholder='Дансны дугаар эсвэл утасны дугаараар хайлт хийх' onChange={(e) => setPagination({...pagination, name:e.target.value})}/>
                  {
                    load?
                    <Button className='mt-1 bg-gray-200'><Spinner/></Button>
                    :
                    <Button onClick={Get} className='mt-1 bg-gray-200'>Хайх</Button>
                  }
                </div>
              </div>
                <table className="min-w-full divide-y divide-gray-200 table-fixed dark:divide-gray-700">
                  <thead className="bg-gray-50 dark:bg-gray-700">
                    <tr>
                      <th scope="col" className="p-4 text-sm font-normal">
                        No
                      </th>
                      <th scope="col" className="py-2 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                        Хичээлийн нэр
                      </th>
                      <th scope="col" className="py-2 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                        Хичээлийн линк
                      </th>
                      <th scope="col" className="py-2 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                        Төрөл
                      </th>
                      <th scope="col" className="py-2 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                        Категори
                      </th>
                      <th scope="col" className="py-2 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                        
                      </th>
                    </tr>
                  </thead>
                  {
                    load?
                    <div className='flex items-center justify-center  p-4'>
                      <Spinner/>
                      <h1 className=''>Уншиж байна ...</h1>
                    </div>
                    :
                    <tbody className="bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700">
                      {
                        data.map((item, index) => {
                          return(
                            <tr className="hover:bg-gray-100 dark:hover:bg-gray-700" key={index}>
                                <td className="p-2 w-4 text-center">
                                  {index+1}
                                </td>
                                <td className="py-2 px-6 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white flex flex-col">
                                  <label className=''>{item.name}</label>
                                </td>
                                <td className="py-2 px-6 text-sm font-medium text-gray-500  dark:text-white text-overflow">{item.link}</td>
                                <td className="py-2 px-6 text-sm font-medium text-gray-500  dark:text-white text-overflow">{item.type}</td>
                                <td className="py-2 px-6 text-sm font-medium text-gray-500  dark:text-white text-overflow">{item.category}</td>
                                <td className="py-2 px-6 text-sm font-medium text-gray-500  dark:text-white text-overflow flex items-center gap-2">
                                  <LessonDelete datas={item} callback={callback}/>
                                </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  }
                </table>
                {
                  data.length === 0 &&
                  <div className='bg-white pt-8 text-center flex items-center justify-center flex-col'>
                    <img className='h-24' src='../../empty.gif'/>
                    <h1 className=''>Хоосон байна...</h1>
                  </div>
                }
               <Paginations initialPage={page} total={pagination.totalPage} alldata={pagination.all} onChange={changePage}/>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Lesson


