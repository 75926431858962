import React, { useRef, useState } from "react";
import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure} from "@nextui-org/react";
import axios from "axios";
import { toast } from "react-toastify";
import { order_uri } from "../../../../utils/url";
import {Input, Spin} from "antd";
import TextArea from "antd/es/input/TextArea";

export default function TrackAddModal({callback}) {
  const {isOpen, onOpen, onOpenChange} = useDisclosure();
  const [load, setLoad] = useState(false);
  const [data, setData] = useState({trackcode:"", phone_no:"", quantity:"1", payment:"", explanation:""});
  const barcodeInputRef = useRef(null);
  const phoneInputRef = useRef(null);
  const quantityRef = useRef(null);
  const paymentRef = useRef(null);
  const explanationRef = useRef(null);

  const [phone, setPhone] = useState(null);
  const [phonee, setPhonee] = useState(null);

  const call = () =>{
    callback();
  }

  const Sub = async()=>{
    if(data.trackcode === "" ||data.phone_no === "" ||data.quantity === "" ||data.payment === "" ||data.explanation === ""){
      return toast.warning("Мэдээлэл дутуу байна");
    }
    setLoad(true);
    try{
        const res = await axios.post(order_uri+'/register', data);
        if(res.status === 200){
            toast.success("Амжилттай");
            call();
            setData({...data, trackcode:"", phone_no:"", payment:"", explanation:""});
            setLoad(false);
            setTimeout(() => {
              barcodeInputRef.current.focus();
            }, 0);
        }
    }catch(err){
        console.log(err);
        setLoad(false);
    }
  }
  
  const GetPhone = async()=>{
    if(data.trackcode === null){
      return
    }
    setLoad(true);
    try{
        const res = await axios.get(order_uri+'/phone/'+`${data.trackcode}`);
        setPhone(res.data);
        setData({...data, phone_no: res.data});
        setLoad(false);
        setTimeout(() => {
          phoneInputRef.current.focus();
        }, 0);
        setPhonee("");
    }catch(err){
        console.log(err);
        setTimeout(() => {
          phoneInputRef.current.focus();
        }, 0);
        setPhonee("");
        setLoad(false);
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      GetPhone();
    }
  };


  const handleKeyDownQuantity = (event) => {
    if (event.key === 'Enter') {
      quantityRef.current.focus();
    }
  };
  const handleKeyDownPayment = (event) => {
    if (event.key === 'Enter') {
      paymentRef.current.focus();
    }
  };
  const handleKeyDownExplanation = (event) => {
    if (event.key === 'Enter') {
      explanationRef.current.focus();
    }
  };


  const onChangeTrack = (event) => {
    setData({...data, trackcode: event});
    // if(event.length > 13){
    //   setTimeout(() => {
    //     phoneInputRef.current.focus();
    //   }, 0);
    // }
  };

  const onChangePhone = (event) => {
      if(event.length !== 8){
        setPhonee("Утасны дугаар буруу байна.")
      }else{
        setPhonee("")
      }
      setData({...data, phone_no: event});
  };

  const handleKeySub = (event) => {
    if (event.key === 'Enter') {
      Sub();
    }
  };

  return (
    <>
      
      <Button size='sm' onClick={onOpen} className="ml-2">Ачаа бүртгэх</Button>
      <Modal placement="top" isOpen={isOpen} onOpenChange={onOpenChange} size="2xl">
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1 border-b">Ачаа бүртгэх</ModalHeader>
              <ModalBody>
                <div className="">
                  <div className="flex items-center gap-2 w-full">
                    <div className="w-full">
                      <h1>Бар код</h1>
                      <Input onKeyDown={handleKeyDown} ref={barcodeInputRef} autoFocus onChange={(e) => onChangeTrack(e.target.value)} value={data.trackcode} className="mt-1" placeholder="Бар код оруулах" size="sm"/>
                    </div>
                    <div className="w-full">
                      <h1>Утасны дугаар</h1>
                      <Input onKeyDown={handleKeyDownQuantity} onChange={(e) => onChangePhone(e.target.value)} ref={phoneInputRef} value={data.phone_no} className="mt-1" placeholder="Утасны дугаар оруулах" type="number" size="sm"/>
                      <p className="text-red-600 text-xs">{phonee}</p>
                      {/* <h1>{phone}</h1> */}
                    </div>
                  </div>
                  <div className="flex items-center gap-2 w-full mt-2">
                    <div className="w-full">
                      <h1>Тоо хэмжээ</h1>
                      <Input onKeyDown={handleKeyDownPayment} ref={quantityRef} onChange={(e) => setData({...data, quantity:e.target.value})} value={data.quantity} className="mt-1" placeholder="0" size="sm" type="number"/>
                    </div>
                    <div className="w-full">
                      <h1>Карго үнэ</h1>
                      <Input onKeyDown={handleKeyDownExplanation} ref={paymentRef} onChange={(e) => setData({...data, payment:e.target.value})} value={data.payment} className="mt-1" placeholder="0" size="sm" type="number"/>
                    </div>
                  </div>
                  <div className="w-full mt-2">
                    <h1>Тайлбар</h1>
                    <TextArea onKeyDown={handleKeySub} ref={explanationRef} onChange={(e) => setData({...data, explanation:e.target.value})} value={data.explanation} className="mt-1" placeholder="Тайлбар" size="sm" />
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onClick={onClose}>
                  Хаах
                </Button>
                {
                    load?
                    <Spin/>
                    :
                    <Button color="primary" onClick={Sub}>
                        Бүртгэх
                    </Button>
                }
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
