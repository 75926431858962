// const mainUrl = "http://localhost:5000";
const mainUrl = "https://backend.darkhanskylogistics.com";

//auth urls
export const org_uri = mainUrl + "/org";
export const loginUri = mainUrl + "/admin";
export const logoutUri = mainUrl + "/org";

export const utils_uri = mainUrl + "/utils";
export const products_uri = mainUrl + "/products";

export const order_uri = mainUrl + "/order";
export const users_uri = mainUrl + "/users";
export const delivery_uri = mainUrl + "/delivery";
export const lesson_uri = mainUrl + "/lesson";
