import axios from 'axios'
import * as XLSX from 'xlsx';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { Button, Spinner } from '@nextui-org/react';
import { order_uri } from '../../utils/url';

const Tailan = ({type, name}) => {
    const [startdate, setStartdate] = useState()
    const [enddate, setEnddate] = useState()
    const [load, setLoad] = useState(false)
    const [types, setTypes] = useState()
    const [names, setNames] = useState()

    useEffect(() => {
        setTypes(type)
        setNames(name)
      }, [type]);

    const downloadData = async () => {
        setLoad(true)
        try{
            const res = await axios.post(order_uri+"/tailan", {startdate:startdate, enddate:enddate, type:types });
            if(res.status === 200){
                var wb = XLSX.utils.book_new(),
                ws = XLSX.utils.json_to_sheet(res.data);
                XLSX.utils.book_append_sheet(wb, ws, "MySheet");
                XLSX.writeFile(wb, `${names +"-" + startdate + "-" + enddate}.xlsx`);
                setLoad(false)
            }
            setLoad(false);
        }catch(err){
            console.log(err)
            setLoad(false)
        }
    }

  return (
    <div className='z-20 flex items-center justify-end my-2 m-2 text-black'>
        <div className='flex gap-2 items-center bg-green-600 p-1'>
            <input onChange={(e) => setStartdate(e.target.value)} type='date' className='bg-white p-1 rounded'/>
            <div>-</div>
            <input onChange={(e) => setEnddate(e.target.value)} type='date' className='bg-white p-1 rounded'/>
        </div>
        {
            load?
            <div className='flex items-center'>
                <Spinner className='ml-2 text-white'/>
                <h1 className='animate-bounce text-black ml-2'>Өгөгдлийг татаж байна.</h1>
            </div>
            :
            <Button size='sm' className='ml-2' onClick={downloadData}>Татах</Button>
        }
    </div>
  )
}

export default Tailan
