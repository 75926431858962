import React, { useState } from "react";
import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure, Spinner, Input} from "@nextui-org/react";
import { POST } from "../../../../utils/requests";
import { delivery_uri } from "../../../../utils/url";
import { toast } from "react-toastify";

export default function AppOkayModal({callback, data}) {
  const {isOpen, onOpen, onOpenChange} = useDisclosure();
  const [load, setLoad] = useState(false);

  const handlecal = () => {
    callback();
  }

  const Sub = async () => {
    setLoad(true);
    try{
        const res = await POST({uri:delivery_uri+'/app', data:{id: data.id, phone_no: data.user.phone_no}});
        if(res.status === 200){
          setLoad(false);
          toast.success("Амжилттай");
          onOpenChange(false);
          handlecal();
        }
    }catch(err){
        console.log(err);
        setLoad(false);
    }
  }

//   const callOpen =()=>{
//     onOpen();
//     Get();
//   }


  return (
    <>
      <Button onClick={onOpen}>Баталгаажуулах</Button>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange} scrollBehavior='inside' size="md">
        <ModalContent>
          {(onClose) => (
            <>
              <ModalBody>
                <div className="font-Roboto mt-6">
                    <h1 className="m-2 uppercase font-bold text-center">Та баталгаажуулахдаа итгэлтэй байна уу ?</h1>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onClick={onClose}>
                  Болих
                </Button>
                {
                  load?
                  <Button color="primary">
                    <Spinner color="white"/>
                  </Button>
                  :
                  <Button color="primary" onClick={Sub}>
                    Баталгаажуулах
                  </Button>
                }
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}