
import { FaCheckCircle } from "react-icons/fa";
import { SiMicrosoftexcel } from 'react-icons/si';
import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure, Progress} from "@nextui-org/react";
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import * as XLSX from 'xlsx';
import { order_uri } from '../../utils/url';
import {toast} from 'react-toastify';

export default function ExcelUrd({callback}) {
  const {isOpen, onOpen, onOpenChange} = useDisclosure();
  const [excel, setExcel] = useState()
    const [loading, setLoading] = useState(true)
    const [number, setNumber] = useState(0);
    const [total, setTotal] = useState(0);

    const handleCallback = () => callback()

    const readUploadFile = (e) => {
        e.preventDefault();
        if (e.target.files) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, { type: "array" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = XLSX.utils.sheet_to_json(worksheet);
                setExcel(json)
            };
            reader.readAsArrayBuffer(e.target.files[0]);
        }
    }

    const HandleSubmit = async (e) => {
        setLoading(false)
        onOpenChange(true);
        try{
            setTotal(excel.length);
            for(let i = 0; i < excel.length; i++){
                const res = await axios.post(order_uri+'/excel', {track: excel[i]});
                if(res.status === 200){
                    setNumber((i / excel.length) * 100);
                    setTotal(prevTotal => prevTotal - 1);
                }
            }
            toast.success("Барааны төлөвийг бүрэн баталж дууслаа !");
            onOpenChange(false);
            handleCallback();
            setLoading(true);
            
        }catch(err){
            console.log(err);
        }
    }

  return (
    <>
        <div>
          <input
              type="file"
              name="upload"
              id="upload"
              onChange={readUploadFile} 
              className='text-xs mx-2 bg-gray-200 rounded p-1'
          />
          <Button className='bg-green-500' size='sm' onClick={HandleSubmit}>Батлах
              <SiMicrosoftexcel className='ml-2'/>
          </Button>
        </div>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1 animate-bounce">{loading?'': 'Ачааллаж байна ...'}</ModalHeader>
              <ModalBody>
                {   
                    loading?
                    <div className="flex items-center gap-4">
                        <h1 className='font-bold uppercase text-'>Төлөв сольж дууссан</h1>
                        <FaCheckCircle size={24} color="green"/>
                    </div>
                    :
                    <div className='pb-10 mb-10'>
                        <Progress hasStripe colorScheme='blue' height='12px' value={number}/>
                        {number.toString().substring(0, 2)} %
                        <h1>{excel?.length} : {total}</h1>
                    </div>
                }
              </ModalBody>
              <ModalFooter>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
