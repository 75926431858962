import {Route, Routes} from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import Home from './pages/Dashboard/Home';
import Login from './pages/Login';
import Users from './pages/Dashboard/Users';
import Requested from './pages/Dashboard/Requested';
import Mng from './pages/Dashboard/Mng';
import Ereen from './pages/Dashboard/Ereen';
import AchaaOlgoh from './pages/Dashboard/AchaaOlgoh';
import AchaaOlgoson from './pages/Dashboard/OlgosonAchaa';
import DeliveryRequest from './pages/Dashboard/Delivery/request';
import DeliveryApprove from './pages/Dashboard/Delivery/approve';
import DeliveryConfirm from './pages/Dashboard/Delivery/confirm';
import Lesson from './pages/Dashboard/Lesson';

function App() {
  return (
    <>
      <Routes>
          <Route path='/' element={<Login/>}/>
          <Route path="/dashboard" element={<Dashboard />}>

          <Route index element={<Home/>}/>
            <Route path="users" element={<Users/>}/>
            <Route path="lesson" element={<Lesson/>}/>
            <Route path="requested" element={<Requested/>}/>
            <Route path="mng" element={<Mng/>}/>
            <Route path="ereen" element={<Ereen/>}/>
            <Route path="olgolt" element={<AchaaOlgoh/>}/>
            <Route path="olgoson" element={<AchaaOlgoson/>}/>
            <Route path="deliveryreq" element={<DeliveryRequest/>}/>
            <Route path="deliveryapp" element={<DeliveryApprove/>}/>
            <Route path="deliveryconfirm" element={<DeliveryConfirm/>}/>
            {/* <Route path="create-order" element={<OrderCreate/>}/>
            <Route path="mng" element={<Mng/>}/>
            <Route path="declined" element={<Declined/>}/>
            <Route path="confirm" element={<Confirm/>}/>
            <Route path="leasing" element={<Leasing/>}/> */}
          </Route>
      </Routes>
    </>
  );
}

export default App;
