import React, { useState } from "react";
import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure, Spinner, Input} from "@nextui-org/react";
import { GET } from "../../../../utils/requests";
import { delivery_uri } from "../../../../utils/url";
import { TYPES } from "../../../../utils/types";
import {Table, TableHeader, TableColumn, TableBody, TableRow, TableCell} from "@nextui-org/react";
import ReqOkayModal from "./reqOkay";
import PrintOkayModal from "./printOkay";
import { MdDeleteOutline } from "react-icons/md";
import { toast } from "react-toastify";
import { FaCheck } from "react-icons/fa";
import moment from "moment";
import ReqRejectModal from "./reqReject";


export default function ReqDetailModal({callback, data}) {
  const {isOpen, onOpen, onOpenChange} = useDisclosure();
  const [load, setLoad] = useState(false);
  const [datas, setDatas] = useState([]);
  const [allpay, setAllpay] = useState();
  const [checkedTracks, setCheckedTracks] = useState([]);
  const [checkload, setCheckload] = useState(false);

  const handlecal = () => {
    callback();
  }

  const Get = async () => {
    setLoad(true);
    try{
        const res = await GET(delivery_uri+`/userfind?status=${TYPES.IRSEN}&phone_no=${data.user.phone_no}`);
        setDatas(res.data);
        setAllpay(res.data.reduce((a,v) =>  a = a + Number(v.payment) , 0 ).toLocaleString());
        setLoad(false);
    }catch(err){
        console.log(err);
        setLoad(false);
    }
  }

  const callOpen =()=>{
    onOpen();
    Get();
  }

  const calculateTotalPayment = () => {
    return checkedTracks.reduce((total, track) => total + parseFloat(track.payment), 0);
  };

  const handleCheckClick = (item) => {
    const track = checkedTracks.filter(it => it.id === item.id);
    if(track.length > 0){
      setDatas((prevData) => prevData.filter((dataItem) => dataItem.id !== item.id));
      return toast.warning("Энэ бараа аль хэдийн сонгогдсон байна !");
    }
    setCheckedTracks((prevCheckedTracks) => [...prevCheckedTracks, item]);
    setDatas((prevData) => prevData.filter((dataItem) => dataItem.id !== item.id));
  };

  const handleDeleteClick = (item) => {
    const track = datas.filter(it => it.id === item.id);
    if(track.length > 0){
      setCheckedTracks((prevData) => prevData.filter((dataItem) => dataItem.id !== item.id));
    }else{
      setCheckedTracks((prevData) => prevData.filter((dataItem) => dataItem.id !== item.id));
      setDatas((prevCheckedTracks) => [...prevCheckedTracks, item]);
    }
  };


  return (
    <>
      <Button size="sm" className="bg-gray-200 text-gray-600" onClick={callOpen}>Дэлгэрэнгүй</Button>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange} scrollBehavior='inside' size="5xl">
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex justify-between gap-1 font-Roboto">
                <h1>Хүргэлтийн дэлгэрэнгүй мэдээлэл ---  <span className="font-bold text-red-800 2xl">{data.user.phone_no}</span></h1>
                <div className="mr-4">
                  <PrintOkayModal data={data} allpay={calculateTotalPayment()} too={checkedTracks.length}/>
                </div>
              </ModalHeader>
              <ModalBody>
                <div className="font-Roboto flex justify-between">
                    <div className="w-full">
                      <Table aria-label="Example static collection table">
                          <TableHeader>
                              <TableColumn>Бар код</TableColumn>
                              <TableColumn>Тоо</TableColumn>
                              <TableColumn>Тайлбар</TableColumn>
                              <TableColumn>Карго</TableColumn>
                              <TableColumn>Он сар</TableColumn>
                              <TableColumn></TableColumn>
                          </TableHeader>
                          <TableBody>
                              {
                                  datas.map((it, index) => {
                                      return(
                                          <TableRow key={index}>
                                              <TableCell>{it.trackcode}</TableCell>
                                              <TableCell>{it.quantity}</TableCell>
                                              <TableCell>{it.explanation}</TableCell>
                                              <TableCell>{it.payment}</TableCell>
                                              <TableCell className="text-xs">{moment(it.date).format('YYYY-MM-DD HH:mm:ss')}</TableCell>
                                              <TableCell>
                                              <div className='bg-gray-200 p-2 rounded-md hover:bg-gray-300 cursor-pointer w-10' onClick={()=> handleCheckClick(it)}>
                                                <FaCheck size={20}/>
                                              </div>
                                              </TableCell>
                                          </TableRow>
                                      )
                                  })
                              }
                          </TableBody>
                      </Table>
                      {
                          load &&
                          <Spinner/>
                      }
                      <h1 className="m-2 uppercase font-bold text-end">Нийт каргоны төлбөр <span> {allpay} </span></h1>
                    </div>
                    <div className='w-60'>
                      <div>
                        <div className='rounded-md bg-gray-200 text-black mt-2 ml-1 p-4'> 
                            <div className='flex justify-between'>
                              <div className='w-full'>
                                <h1>Ачаа тоо</h1>
                                <h1 className='font-bold text-xl'>{checkedTracks.length}ш</h1>
                              </div>
                              <div className='w-full'>
                                <h1>Ачаа дүн</h1>
                                <h1 className='font-bold text-xl'>{calculateTotalPayment()}</h1>
                              </div>
                            </div>
                            {
                              checkedTracks.map((it, index) => {
                                return(
                                  <div key={index} className='flex justify-between items-center ring-2 ring-white bg-green-600 rounded-sm mt-2 p-2'>
                                    <div>
                                      <h1>{it.phone_no}</h1>
                                      <h1 className='text-gray-200 text-xs'>{it.trackcode}</h1>
                                    </div>
                                    <h1 className='text-gray-100'>{it.payment}</h1>
                                    <MdDeleteOutline onClick={()=> handleDeleteClick(it)} className='cursor-pointer' color='red' size={20}/>
                                  </div>
                                )
                              })
                            }
                        </div>
                      </div>
                    </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onClick={onClose}>
                  Болих
                </Button>
                <ReqRejectModal callback={callback} data={data}/>
                <ReqOkayModal callback={callback} data={checkedTracks} user={data}/>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}